import { _ } from "i18n";
import type { ClubPlayersInfo } from "libs/apiClient";
import client from "libs/client";
import { useState } from "react";
import { useToast } from "ui-kit";

interface GenerateMobileLinkModalProps {
	playerData?: ClubPlayersInfo;
	close: () => void;
}

export default function GenerateMobileLinkModal({ playerData, close }: GenerateMobileLinkModalProps) {
	const { addSuccessToast, addErrorToast } = useToast();
	const [url, setUrl] = useState<string | undefined>();

	const mutation = client.generateSignUpLink.useMutation();

	const generateLink = () => {
		mutation.mutate(
			{ body: { playerId: playerData?.id } },
			{
				onSuccess: (res) => {
					setUrl(res?.data.shortLink);
				},
				onError: (res) => {
					addErrorToast(res.message);
				},
			},
		);
	};

	const copyLink = () => {
		if (url) {
			navigator.clipboard.writeText(url);
			addSuccessToast(_("signupLink.copiedToClipboard"));
		}
	};

	const shareLink = () => {
		navigator.share({ url });
		close();
	};

	return (
		<>
			<div className="generateLinkModal-container">
				{!url ? (
					<>
						<span className="icon-new icon-blue icon-warning-new icon-50" />
						<div className="generateLinkModal-textLarge">{_("generate.confirmation.are_you_sure")}</div>
						<div>{_("signupLink.confirmation.description", { title: `${playerData?.firstName} ${playerData?.lastName}` })}</div>
						<div className="generateLinkModal-buttons">
							<div className="modalButtons-blue" onClick={() => generateLink()}>
								{_("button.generate_link")}
							</div>
							<div className="modalButtons-cancel" onClick={() => close()}>
								{_("btn.cancel")}
							</div>
						</div>
					</>
				) : (
					<>
						<span className="icon-success icon icon-tick-new icon-50" />
						<div className="generateLinkModal-textLarge">{_("signupLink.successfullyGenerated")}</div>
						{typeof navigator.share === "function" ? (
							<div className="generateLinkModal-buttons">
								<div className="modalButtons-blue" onClick={() => shareLink()}>
									{_("label.share")}
								</div>
							</div>
						) : (
							<div className="generateLinkModal-buttons">
								<div className="modalButtons-blue" onClick={() => copyLink()}>
									{_("label.copy_link")}
								</div>
							</div>
						)}
					</>
				)}
			</div>
		</>
	);
}
